import React, { useEffect, useRef } from "react";
import s from "./Line3.scss";

import gsap from "gsap";
import DrawSVGPlugin from "utils/DrawSVGPlugin.js";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

import { scroll } from "constants/Scroll";

export const Line3 = () => {
  const lineRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      lineRef.current,
      {
        drawSVG: "0%"
      },
      {
        duration: 4.5,
        delay: 2.5,
        drawSVG: "100%",
        ease: "linear",
        scrollTrigger: {
          scroller: scroll.container,
          trigger: lineRef.current,
          scrub: 1,
          start: "top 88%",
          end: "-=0px"
        }
      }
    );
  }, []);

  return (
    <svg
      className={s.line}
      xmlns="http://www.w3.org/2000/svg"
      width="300"
      height="1920"
      viewBox="0 0 300 1920"
      fill="none"
    >
      <path
        ref={lineRef}
        d="M1.5 0V1780C30.5 1841.5 104.5 1918.5 184.5 1918.5C274 1918.5 348 1918.5 376 1918.5"
        stroke="#1B52AD"
        strokeOpacity="0.5"
        strokeWidth="2"
      />
    </svg>
  );
};
