import React, { useEffect, useRef } from 'react';
import s from './Line1.scss';

import gsap from 'gsap';
import { scroll } from "constants/Scroll"
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import DrawSVGPlugin from 'utils/DrawSVGPlugin.js';
gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

export const Line1 = () => {

  const lineRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      lineRef.current,
      {
        drawSVG: '0%',
      },
      {
        duration: 4.5,
        delay: 2.5,
        drawSVG: '100%',
        ease: 'linear',
      }
    );
  },[])

  return (
    <svg className={s.line} xmlns="http://www.w3.org/2000/svg" width="545" height="825" viewBox="0 0 545 825" fill="none">
        <path ref={lineRef} opacity="0.5" d="M544 0V623.103C544 733.56 454.457 823.103 344 823.103H0.507568" stroke="#1B52AD" stroke-width="2"/>
    </svg>
  );
};
