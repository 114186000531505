import React, { useEffect, useRef } from 'react';
import s from './Line4.scss';

import gsap from 'gsap';
import DrawSVGPlugin from 'utils/DrawSVGPlugin.js';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

import { scroll } from "constants/Scroll"


export const Line4 = () => {

  const lineRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      lineRef.current,
      {
        drawSVG: '0%',
      },
      {
        duration: 4.5,
        delay: 2.5,
        drawSVG: '100%',
        ease: 'linear',
        scrollTrigger: {
          scroller: scroll.container,
          trigger: lineRef.current,
          scrub: 1,
          start: "top 88%",
          end: '-=0px',
        },
      }
    );
  },[])

  return (
    <svg className={s.line} xmlns="http://www.w3.org/2000/svg" width="354" height="395" viewBox="0 0 354 395" fill="none">
      <path ref={lineRef} opacity="0.5" d="M352.5 0V194C352.5 304.457 262.957 394 152.5 394H0" stroke="#1B52AD" stroke-width="2"/>
    </svg>
  );
};
